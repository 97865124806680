<template>
  <div class="overflow-auto">
    <div class="flex items-center font-semibold text-black text-xl px-6 my-4">
      <svg
        @click="$router.go(-1)"
        class="flex cursor-pointer items-center mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 12H6M12 5l-7 7 7 7" />
      </svg>

      New Ticket
    </div>

    <div
      class="
        px-6
        mt-4
        text-gray-500 text-sm
        xl:w-1/2
        lg:w-1/2
        md:w-full
        sm:w-full
      "
    >
      <p>
        If you would like to know about the availability of a product, have a
        complaint of a question, fill this form out and our customer service
        team will get back to your as soon as possible.
      </p>
    </div>
    <div class="xl:w-1/2 lg:w-1/2 md:w-full sm:w-full">
      <!-- <div
        class="bg-white px-6 pt-4 text-sm text-gray-500 uppercase flex items-center"
      >
        <span class=" w-1/2"> email address* </span>
        <input
          class="px-4 w-full py-2 border text-gray-800 border-gray-300 rounded outline-none"
          type="text"
          placeholder="your@email.com"
        />
      </div> -->
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> ticket category* </span>
        <select
          v-model="ticket.category"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          placeholder="your@email.com"
        >
          <option value="" disabled>Select Category</option>
          <option value="complaint">Compaint</option>
          <option value="faulty item(s)">Faulty Items</option>
          <option value="problem with order">Problem with Order</option>
        </select>
      </div>
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> subject* </span>
        <input
          v-model="ticket.subject"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="text"
          placeholder="What can we help you with?"
        />
      </div>

      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> message* </span>
        <textarea
          v-model="ticket.message"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          placeholder="Please include Order # Part/Model Numbers, etc as necessary... "
        />
      </div>
    </div>

    <div
      class="
        bg-white
        px-6
        pt-4
        text-sm text-gray-500
        uppercase
        xl:flex
        lg:flex
        md:flex
        items-center
        mb-10
      "
    >
      <div class="mr-20 mb-2">attachments<br />(optional)</div>

      <div
        ref="dragDrop"
        class="
          items-center
          text-center
          border
          rounded
          border-gray-400
          hover:border-black
          border-dashed
          p-10
          px-20
        "
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop="filesSelected"
      >
        <div class="text-black text-md pb-1">Drag and Drop files here</div>

        <div class="text-gray-400 text-xs pb-3">
          Files supported: jpg, png, jpeg, pdf
        </div>

        <label
          class="px-2 py-1 inline-block bg-black text-white text-sm rounded"
        >
          <input @change="filesSelected" type="file" id="" class="hidden" />
          Choose Files
        </label>
      </div>
      <div>
        <div
          class="
            border border-gray-200
            flex
            justify-between
            ml-2
            bg-gray-100
            p-3
          "
          v-for="(file, i) in ticket.files"
          :key="i"
        >
          {{ file.name }}
          <button
            @click="ticket.files = ticket.files.filter((f, ind) => ind != i)"
            class="text-red-500 px-3 font-bold ml-2"
          >
            X
          </button>
        </div>
      </div>
    </div>

    <div class="px-6 flex items-center mb-20">
      <button
        class="
          bg-gray-500
          hover:bg-black
          text-white text-md text-center
          mr-3
          rounded
          px-8
          py-1
        "
      >
        Cancel</button
      ><button
        :class="{ 'cursor-not-allowed ': !canSave }"
        :disabled="!canSave"
        @click.prevent="addSupportTicket(ticket)"
        class="button text-md text-center rounded px-8 py-1"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
// import pdfsvg from "../base/pdfsvg";
import { mapActions } from "vuex";
export default {
  name: "NewTicket",
  data() {
    return {
      ticket: {
        category: "",
        subject: "",
        message: "",
        files: [],
      },
    };
  },
  methods: {
    ...mapActions(["addSupportTicket"]),
    handleDragOver() {
      this.$refs.dragDrop.style.background = "#eee";
    },
    handleDragLeave() {
      this.$refs.dragDrop.style.background = "";
    },

    filesSelected(e) {
      e.preventDefault();
      this.$refs.dragDrop.style.background = "";

      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        this.ticket.files.push(...e.dataTransfer.files);
      }

      if (e.target && e.target.files && e.target.files.length) {
        this.ticket.files.push(...e.target.files);
        e.target.value = null;
      }
    },
  },
  computed: {
    canSave() {
      return this.ticket.category && this.ticket.subject && this.ticket.message;
    },
  },
  components: {},
};
</script>




<style scoped>
.button {
  background: #d4b376;
  color: #000;
}

.button:hover {
  background: #000;
  color: #fff;
}
.inq-table {
  th {
    position: sticky;
    z-index: 48;
  }
}

.bg-gray {
  background-color: #ccc !important;
}

ul li.liborder:hover,
ul li.liborder:active {
  border-bottom: solid 4px;
  border-color: #000;
}
</style>